import React, { useState } from 'react'
import { FaTimes, FaCheck } from 'react-icons/fa'
import BEMHelper from 'react-bem-helper'
import './Button.scss'
import IconButtonPure from '../../../wal-common/src/components/Icon/IconButtonPure/IconButtonPure'

const classes = new BEMHelper({
  name: 'Button',
})

function Button({
  onClick: onClickIn,
  disabled: disabledDefault,
  children,
  dataTestid,
  withConfirmation,
}) {
  const [showConfirm, setShowConfirm] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(false)

  return (
    <div className="Button">
      <button
        onClick={() => {
          if (withConfirmation) {
            setBtnDisabled(true)
            setShowConfirm(true)
            return
          }
          onClickIn()
        }}
        data-testid={dataTestid}
        className="Button__Btn button is-primary is-rounded is-medium"
        disabled={btnDisabled || disabledDefault}
      >
        {children}
      </button>
      {withConfirmation && (
        <div
          {...classes('Confirm', null, {
            'has-text-black m-t-md p-l-md walEffectSlideDown': true,
            'is-active': showConfirm,
          })}
          data-testid="confirm"
        >
          <div>Уверен?</div>
          <div
            role="button"
            tabIndex="0"
            className="m-l-md"
            data-testid="confirm_no"
            onClick={() => {
              setBtnDisabled(false)
              setShowConfirm(false)
            }}
          >
            <IconButtonPure
              modifier="lg"
              addClasses="has-text-danger"
              Icon={FaTimes}
            />
          </div>
          <div
            role="button"
            tabIndex="0"
            data-testid="confirm_yes"
            className="m-l-md"
            onClick={() => {
              setShowConfirm(false)
              if (onClickIn) {
                onClickIn()
              }
            }}
          >
            <IconButtonPure
              modifier="lg"
              addClasses="has-text-success"
              Icon={FaCheck}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Button
