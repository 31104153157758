import React from 'react'
import './IconButtonPure.scss'
import { getClasses } from '../../../../../utils'

const classes = getClasses('IconButtonPure')

function IconButtonPure({ Icon, addClasses, modifier, onClick }) {
  return (
    <div
      aria-label="Button"
      role="button"
      tabIndex="0"
      onClick={onClick}
      {...classes()}
    >
      <Icon {...classes('Btn', modifier, addClasses)} />
    </div>
  )
}
export default IconButtonPure
