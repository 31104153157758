import React from 'react'
import { navigate } from 'gatsby'
import { useMutation } from '@apollo/react-hooks'
import LayoutCenter from '../../wal-common/src/components/Layout/LayoutCenter/LayoutCenter'
import { generateId } from '../../utils'
import { CREATE_GAME } from '../../graphql/queries'
import Button from '../FormElement/Button/Button'

function IndexPage() {
  const [createGame, { called }] = useMutation(CREATE_GAME)

  return (
    <LayoutCenter>
      <Button
        withConfirmation
        onClick={() => {
          const id = generateId()

          createGame({
            variables: {
              id,
            },
          }).then(({ data }) => {
            const {
              createGame: { id },
            } = data
            navigate(`/game/${id}`)
          })
        }}
        dataTestid="create"
        disabled={called}
      >
        начать новую игру
      </Button>
    </LayoutCenter>
  )
}

export default IndexPage
