import React from 'react'
import Layout from '../components/layout'
import IndexPage from '../components/IndexPage/IndexPage'

export default function () {
  return (
    <Layout>
      <IndexPage />
    </Layout>
  )
}
